import $ from 'jquery'
import '../external/sticky-sidebar.js'
import { parseChars } from './util'

$(document).ready(function () {
  if ($('.program-list').length) {
    handleNewFilters()
  }
})

function debounce (func, delay) {
  let timer
  return function (...args) {
    clearTimeout(timer)
    timer = setTimeout(() => func.apply(this, args), delay)
  }
}

function handleNewFilters () {
  const arrayOfFilters = $('.program-list').data('array-filters')

  // Fill the filter option with the value from query param
  const filtersMap = new Map()
  arrayOfFilters.filter(([filterKey, filterValue]) => filterKey !== 's').forEach(filter => {
    const [, filterValue] = filter.split(':')
    const filterElement = $(`#${filterValue}`)
    const filterLabel = filterElement.text()
    const filterGroupParent = filterElement.data('group-parent')

    let values = [filterLabel]
    if (filtersMap.has(filterGroupParent)) {
      values = filtersMap.get(filterGroupParent)
      values.push(filterLabel)
    }
    filtersMap.set(filterGroupParent, values)
  })

  for (const [key, value] of filtersMap) {
    const groupParent = $(`#${key}`)
    groupParent.find('.courseSelect__btn button p').text(value.join(', '))
  }

  $('.clear-input').on('click', function () {
    const searchParams = new URLSearchParams(window.location.search)
    $(this).prev('input').val('')

    searchParams.delete('s')

    performQuery({ searchParams })
  })

  $('.filter-search input').on('keyup', function () {
    const searchParams = new URLSearchParams(window.location.search)

    if (!$(this).val()) {
      searchParams.delete('s')
    } else if (searchParams.has('s')) {
      searchParams.set('s', $(this).val())
    } else {
      searchParams.append('s', $(this).val())
    }

    performQuery({ searchParams })
  })

  $('.courseSelect__options .items li a').on('click', function () {
    const value = $(this).attr('id')
    const group = $(this).attr('data-group-parent') + '[]'

    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has(group)) {
      searchParams.set(group, value)
    }

    if (!searchParams.has(group) && value) {
      searchParams.append(group, value)
    }

    if (!value) {
      searchParams.delete(group)
    }

    performQuery({ searchParams })
  })

  $('.program-list__list .link-list a').on('click', function () {
    const programId = $(this).attr('id')
    performGADataLayerProductClick({ programId })
  })

  const performQuery = ({ searchParams }) => {
    const url = $('.program-list').data('url')

    const queryParams = searchParams.toString()
    const browserUrl = url + decodeURIComponent((queryParams ? `?${queryParams}` : ''))
    window.history.pushState('object or string', 'Title', browserUrl)

    const allowedQueryParams = ['fagomrade[]', 'utdanningsniva[]', 's']
    const searchParamsEntries = searchParams.entries()
    for (const [key] of searchParamsEntries) {
      if (!allowedQueryParams.includes(key)) {
        searchParams.delete(key)
      }
    }

    if (!searchParams.toString()) {
      const itemsCheckedToShow = []
      $('.program-list__list li.list-item').each(function () {
        if ($(this).hasClass('d-none')) $(this).removeClass('d-none')
        itemsCheckedToShow.push(JSON.parse($(this).attr('data-item')))
      })
      updateProgramsOnDataLayer(itemsCheckedToShow)
    } else {
      const searchParamFilters = []
      const searchParamFiltersMap = new Map()
      const searchTerm = searchParams.get('s')
      searchParams.delete('s')

      for (const [key, value] of searchParams.entries()) {
        if (allowedQueryParams.includes(key)) {
          const sanitizedKey = key.replace('[]', '')
          searchParamFilters.push(`${sanitizedKey}:${value}`)
          let count = 1
          if (searchParamFiltersMap.has(sanitizedKey)) {
            count = searchParamFiltersMap.get(sanitizedKey) + 1
          }
          searchParamFiltersMap.set(sanitizedKey, count)
        }
      }

      const itemsCheckedToShow = []
      /**
       * The logic implemented to check if the filters are matching is as the following:
       * An Map object is created with the amount of filters from the query param as { fagomrade: 1, utdanningsniva: 2 } for example.
       * This means that we have one filter from fagomrade and two from uutdanningsniva.
       * To be shown, a program should have at least one of each filter, since the condition checked is OR between filters from the same group and AND between different groups.
       * Fo each program, I create a similar object as above and compare the amount of filters
       */
      $('.program-list__list li.list-item').each(function () {
        const filters = JSON.parse($(this).attr('data-filters'))
        const filtersMatchMap = new Map()

        if (searchTerm && !parseChars($(this).find('h2').text()).toLowerCase().includes(parseChars(searchTerm.toLowerCase()))) {
          $(this).addClass('d-none')
          return
        }

        searchParamFilters.forEach(searchFilter => {
          if (filters.join(',').split(',').indexOf(searchFilter) >= 0) {
            const [key] = searchFilter.split(':')
            let count = 1
            if (filtersMatchMap.has(key)) {
              count = filtersMatchMap.get(key) + 1
            }
            filtersMatchMap.set(key, count)
          }
        })

        let shouldShow = true
        for (const [key] of searchParamFiltersMap) {
          if (!filtersMatchMap.has(key)) shouldShow = false
        }

        if (!shouldShow) {
          $(this).addClass('d-none')
          return
        }

        $(this).removeClass('d-none')

        const dataItem = JSON.parse($(this).attr('data-item'))
        itemsCheckedToShow.push(dataItem)
      })

      if (itemsCheckedToShow.length) {
        debounceUpdateProgramsOnDataLayer(itemsCheckedToShow)
      }
    }

    // Update total programs
    const totalProgramsVisible = $('.program-list__list li.list-item').not('.d-none').length
    $('.results-number p').text(`Viser ${totalProgramsVisible} studier`)
  }

  const updateProgramsOnDataLayer = (programs) => {
    const chunks = chunkArray(programs, 67)

    chunks.forEach((chunk, chunkIndex) => {
      window.dataLayer.push({ // GA - dataLayer
        event: 'productImpression',
        ecommerce: {
          impressions: chunk.map((item, index) => ({
            name: item.title,
            id: item.salesforce_program_id || item.salesforce_course_offering_id,
            price: item.conductOptions && item.conductOptions.conductPriceWithoutLabel,
            brand: 'NKI',
            category: item.fieldsOfStudy,
            list: 'Finn ditt retning',
            position: (chunkIndex * 67) + index + 1
          }))
        },
        page: chunkIndex + 1,
        totalPages: chunks.length,
        isPaginated: true
      })
    })
  }

  const debounceUpdateProgramsOnDataLayer = debounce(updateProgramsOnDataLayer, 1000)

  const performGADataLayerProductClick = ({ programId }) => {
    const gaDataLayerService = $('.program-list').data('ga-data-layer-service')

    $.ajax({
      url: gaDataLayerService,
      data: { programId, action: 'Product click' },
      success: function (data) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'productClick',
          ecommerce: {
            click: {
              actionField: { list: 'Finn ditt retning' },
              products: data.products
            }
          }
        })
      }
    })
  }

  // Check URL filters and hide programs if necessary
  (function () {
    const searchParams = new URLSearchParams(window.location.search)
    performQuery({ searchParams })
  })()
}

function chunkArray (array, size) {
  const result = []

  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size))
  }

  return result
}
